import { ReactNode } from 'react';

export const Trademark = ({children}:{ children: ReactNode }) => (
  <span style={{marginLeft: '.3em', marginTop: 0}}>
    {children}
    <sup style={{ fontSize: '.7em'}}>
      &trade;
    </sup>
  </span>
);
