import { ContentModal } from './ContentModal';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';

export const HelpModal = ({ 
    title = '',
    children,
}:{ 
    title: string;
    children: JSX.Element,
}) => {
    return (
        <ContentModal
            trigger={<IconButton><HelpIcon/></IconButton>}
            header={title}
        >
            { children }
        </ContentModal>
    )
}