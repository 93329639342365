import { BrowserRouter as Router } from 'react-router-dom';
import loadable from '@loadable/component';
import { ScrollToTop } from 'components';
import { useSessionContext } from 'hooks';
import { Elements } from '@stripe/react-stripe-js';
import {PathRouteProps} from 'react-router';

export interface PrivateRouteProps extends PathRouteProps {
  redirect?: string;
}

const PublicRoutes = loadable(() => import('./Public'));

const AuthRoutes = loadable(() => import('./Auth'));

const DashboardRoutes = loadable(() => import('./Dashboard'));

const BuyerRoutes = loadable(() => import('./Buyer'));

const SellerRoutes = loadable(() => import('./Seller'));

const SupportRoutes = loadable(() => import('./Support'));

const EventRoutes = loadable(() => import('./Event'));

const StoreRoutes = loadable(() => import('./Store'));

const KwixlChatRoutes = loadable(() => import('./KwixlChat'));

export const ROUTES = {
    home: '/',
    homeAlt: '/home',
    signup: '/signup',
    pricing: '/pricing',
    channel: '/channel',
    facebook: '/facebook',
  
    // Auth
    signin: '/auth/signin',
    signout: '/auth/signout',
    reminder: '/auth/reminder',
    yt_auth: '/auth/oauth/yt',
    verify_method: (method: string) => `/auth/verify/${method}`,
    confirm: '/auth/confirm',
    event_login: (id: string, token: string) => `/auth/event/${id}/${token}`,
    facebook_connect: '/auth/facebook',
  
    // Account
    account: '/account',
    onboard: '/account/onboard',
    onboard_status: '/account/onboard/status',
  
    // Demo
    demo: '/demo',
    demo_buyers: '/demo/buyers',
    demo_selling: '/demo/selling',
    demo_formats: '/demo/formats',
    demo_video: '/demo_video',
    demo_facebook: '/demo/facebook',
    demo_enter: '/demo/enter',
  
    // Store
    store: (vanity: string) => `/store/${vanity}`,
    store_go: (vanity: string) => `/go/${vanity}`,
  
    // Support 
    support: '/support',
    
    // Presenter
    presenter: '/presenter',
    presenter_event_live: (eventId = ':eventId') => `/presenter/event/${eventId}/live`,
    presenter_events: '/presenter/events',
  
    // Seller
    cp: '/cp',
    cp_org: '/cp/org',
    cp_org_stats: '/cp/org/stats',
    cp_event: '/cp/event',
    cp_event_edit: (eventId = ':eventId') => `/cp/event/${eventId}/edit`,
    cp_event_comments: (eventId = ':eventId') => `/cp/event/${eventId}/comments`,
    cp_buyer_report: (eventId = ':eventId') => `/cp/event/${eventId}/buyers`,
    cp_event_summary: (eventId = ':eventId') => `/cp/event/${eventId}/summary`,
    cp_event_live: (eventId = ':eventId') => `/cp/event/${eventId}/live`,
    cp_settings: '/cp/settings',
    cp_settings_event: '/cp/settings/event',
    cp_settings_address: '/cp/settings/address',
    cp_settings_payment: '/cp/settings/payment',
    cp_settings_page: '/cp/settings/page',
    cp_settings_policy: '/cp/settings/policy',
    cp_settings_shipping: '/cp/settings/shipping',
    cp_billing: '/cp/billing',
    cp_billing_invoice: (invoiceId = ':invoiceId') => `/cp/billing/invoice/${invoiceId}`,
    cp_customers: '/cp/customers',
    cp_customers_groups: '/cp/customers/groups',
    cp_customers_blocked: '/cp/customers/blocked',
    cp_customers_conversation: (uid = ':uid') => `/cp/customers/${uid}/conversation`,

    /* FACEBOOK */
    cp_facebook: '/cp/fb',
    cp_facebook_pages: '/cp/fb/pages',
    cp_facebook_posts: `/cp/fb/posts`,
    cp_facebook_users: `/cp/fb/users`,
    cp_facebook_page_replies: (pageId = ':pageId') => `/cp/fb/${pageId}/replies`,
    cp_facebook_page_users: (pageId = ':pageId') => `/cp/fb/${pageId}/users/`,
    cp_facebook_page_posts: (pageId: string) => `/cp/fb/${pageId}/posts`,
    cp_facebook_page_archived_posts: (pageId = ':pageId') => `/cp/fb/${pageId}/posts/archived`,
    cp_facebook_archived_posts: '/cp/fb/posts/archived',
    cp_facebook_comments: `/cp/fb/comments`,
    cp_facebook_post_activity: (postId: string) => `/cp/fb/${postId}/activity`,
    cp_facebook_settings: `/cp/fb/settings`,
    
    /* TIKTOK */
    cp_tiktok: '/cp/tiktok',
    
    cp_store_inventory: `/cp/store/inventory`,
    cp_inventory: '/cp/inventory',

    cp_invoices: '/cp/invoices',
    cp_invoices_event: (id = ':eventId') => `/cp/invoices/event/${id}`,
    cp_invoices_customers: `/cp/invoices/customers`,

    application: '/selling/application',
    categories: '/categories',
    contact: '/contact',
    faq: '/faq',
    privacy: '/privacy',
    terms: '/terms',
    blocked: '/blocked',
    page404: '/404',
    events: '/events',
    auth: '/auth',
    loginlink: '/auth/loginlink',
    instagram_auth: '/auth/ig',
    tiktok_auth: '/auth/tiktok',
    me: '/me',
    // My routes
    my: '/my',
    my_notifications: '/my/notifications',
    my_messages: '/my/messages',
    my_messages_thread: (threadId: string) => `/my/messages/${threadId}`,
    my_profile: '/my/profile',
    my_invoices: '/my/invoices',
    my_invoice: (invoiceId: string) => `/my/invoice/${invoiceId}`,
    my_payment_methods: "/my/paymethods",
    my_subscriptions: '/my/subscriptions',
  
    selling: '/selling',
    sellingFacebook: '/selling/facebook',
    checkout: '/checkout',
    invite: '/invite',
    invite_code: (code: string) => `/invite/${code}`,
    register_code: (code: string) => `/register/${code}`,
    ticket: (eventId: string) => `/${eventId}/ticket`,
    category: (slug: string) => `/category/${slug}`,
    event: (eventId: string) => `/event/${eventId}`,
    events_slug: (slug: string) => `/events/${slug}`,
    shopify: `/shopify`,

    /* KwixlChat */
    kwixlchat_landing:'/kwixlchat',
    cp_kwixlchat: '/cp/kwixlchat',
    cp_kwixlchat_account_automations: (accountId = ':accountId') => `/cp/kwixlchat/${accountId}/automations`,
    cp_kwixlchat_account_media: (accountId = ':accountId') => `/cp/kwixlchat/${accountId}/media`,
    cp_kwixlchat_account_contacts: (accountId = ':accountId') => `/cp/kwixlchat/${accountId}/contacts`,

  };

export const InternalRoutes = () => {
  const {stripePromise} = useSessionContext();
  return (
    <Router>
      <ScrollToTop>
        <PublicRoutes />
        <KwixlChatRoutes/>
        <AuthRoutes/>
        <Elements stripe={stripePromise}>
          <DashboardRoutes /> 
          <BuyerRoutes/> 
          <SellerRoutes/>
          <EventRoutes/>
          <StoreRoutes/>
        </Elements>
        <SupportRoutes/>
      </ScrollToTop>
    </Router>
  )
          
}